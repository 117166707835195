.controls-container {
    display: flex;
    flex-direction: row;
    padding: 1rem 2rem;
    margin: 2rem;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    width: 10vw;
}
.center-icon {
    scale: 1.5;
    color: white;
    margin: auto;
    cursor: pointer;
}
.refresh-icon {
    scale: 1.5;
    color: white;
    margin: auto;
    cursor: pointer;
}
.pause-icon {
    scale: 1.5; /* Adjust scale as needed */
    color: white; /* Ensure the icon color matches */
    margin: auto;
    cursor: pointer; /* Make it clickable */
}