/* About Section General Styling */
#about {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#about h1 {
    text-align: center;
}

/* About Container (Main Grid) */
.about__container {
    display: grid;
    grid-template-columns: 30% 65%;
    gap: 5%;
    align-items: center;
}

/* Image Section Styling */
.about__me {
    width: 100%;
    display: grid;
    border-radius: 2rem;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
}

/* Content Section Styling */
.about__content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

/* Cards Grid */
.about__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

/* Individual Card Styling */
.about__card {
    background: var(--color-bg-variant);
    border: 2px solid var(--color-white);
    border-radius: 1rem;
    padding: 0.6rem;
    text-align: center;
    transition: var(--transition);
}

/* Hover Effect on Cards */
.about__card:hover {
    background: transparent;
    border: 2px solid var(--color-primary);
    cursor: default;
}

/* Icons inside Cards */
.about__icon {
    color: var(--color-primary);
    font-size: 3rem;
}

/* Text inside Cards */
.about__card h5 {
    font-size: 0.95rem;
}

/* Media Queries for Medium Devices (Tablets) */
@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}

/* Media Queries for Small Devices (Mobiles) */
@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0.25rem auto 0.25rem;
    }

    .about__cards {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }

    .about__container p {
        font-size: 0.75rem;
    }

    .about__card h5 {
        font-size: 0.7rem;
    }
}
