/* NBA-API.css */
.nba-api-page {
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.nba-api-container {
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #333;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--color-gray);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.nba-api-container h1 {
    font-size: xx-large;
    color: var(--color-primary);
}

.nba-api-description {
    font-size: 1.2rem;
    color: var(--color-light);
    text-align: center;
    width: 80%;
    margin-bottom: 1rem;
}

.nba-api-dropdown-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.nba-api-dropdown {
    width: 30%;
}

.nba-api-label {
    color: var(--primary-color);
    font-weight: bold;
    margin-bottom: 5px;
}

.nba-api-select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
}

.nba-api-placeholder-image {
    width: 100%;
    height: auto;
    max-height: 90%; /* Adjust as needed */
    object-fit: contain;
    margin: 20px 0;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
}

.nba-api-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    overflow: hidden;
}

.nba-api-submit-btn {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: var(--color-light);
    background-color: var(--color-primary);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 10%;
}

.nba-api-submit-btn:hover {
    background-color: var(--color-secondary);
}

.nba-api-progress-container {
    width: 100%;
    background-color: var(--gray);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .nba-api-progress-bar {
    height: 20px;
    background-color: var(--tertiary-color);
    width: 0;
  }

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.nba-api-spinner {
    width: 50px; /* Adjust size as needed */
    height: 50px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #006bb6; /* NBA blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 20px auto; /* Center the spinner */
}

.nba-api-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 10px;
    flex-direction: column;
    align-items: center;
}


