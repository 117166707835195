/* GENERAL SECTION STYLING */
#projects {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#projects h1 {
    text-align: center;
    margin-bottom: 1rem;
    color: var(--color-white);
}

.projects-container {
    width: 95%;
    margin: auto;
    margin-bottom: 2rem;
}

/* PROJECT TABS STYLING */
.project-tabs {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    background-color: var(--color-primary);
    border: 0.1rem solid #000000;
}

.project-tabs label {
    cursor: pointer;
    padding: 0.5rem;
    color: var(--color-white);
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: center;
    flex: 1;
    align-content: center;
}

/* RADIO BUTTON STYLING */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding: 0 12px;
    letter-spacing: 3px;
    line-height: 30px;
    font-weight: 500;
    color: #fff;
    margin: 0 10px;
    transition: all 0.2s ease;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: "";
    border-radius: 2px;
    height: 20px;
    padding: 5px;
    background-color: var(--color-secondary);
    position: absolute;
    bottom: 0;
    left: 6px;
    top: -4px;
    z-index: -1;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    width: 0;
}

[type="radio"]:checked + label:after {
    opacity: 1;
    width: calc(100% - 24px);
}

/* CHECKBOX COLOR STYLING */
.checkbox-all:checked + label:after,
.checkbox-all:not(:checked) + label:after {
    background-color: hotpink;
}

.checkbox-data-science:checked + label:after,
.checkbox-data-science:not(:checked) + label:after {
    background-color: #f1c40f;
}

.checkbox-machine-learning:checked + label:after,
.checkbox-machine-learning:not(:checked) + label:after {
    background-color: #ff932b;
}

.checkbox-software-development:checked + label:after,
.checkbox-software-development:not(:checked) + label:after {
    background-color: #64e1fe;
}

.checkbox-other:checked + label:after,
.checkbox-other:not(:checked) + label:after {
    background-color: purple;
}

.checkbox-label:hover {
    background-color: var(--color-secondary);
    transition: all 0.2s ease;
    border-radius: 1rem;
}

.checkbox-label {
    font-size: 1.2rem;

}


/* PROJECT GRID STYLING */
.projects {
    display: grid;
    gap: 0.25rem;
    width: 100%;
    margin: 0 auto;
}

.project {
    opacity: 0;
    transform: scale(0);
    padding: 0;
    margin: 0;
    display: none;
    border-width: 0;
}

.project.show {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0.1rem solid var(--text-dark);
    opacity: 1;
    margin-top: 0.5rem;
    visibility: visible;
    transform: scale(1);
    border-width: 2px;
    margin-right: 0.1rem;
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: var(--color-gray);
}

/* PROJECT CONTENT STYLING */
.project-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: underline;
}

.project-description {
    color: var(--color-white);
    margin: 0.25rem 0;
    overflow: scroll;
}

.project-img {
    width: 100%;
    height: auto;
    margin: 0.1rem 0 0.25rem;
    object-fit: cover;
}

/* TAG STYLING */
.tags-container {
    display: flex;
    overflow: scroll;
    justify-content: left;
    margin: 0.75rem 0;
}

.tag {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 5px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
}

/* TECHNOLOGY TAG COLORS */
.python-tag { background-color: #306998; color: white; }
.flask-tag { background-color: #000000; color: white; }
.react-tag { background-color: #61dafb; color: black; }
.java-tag { background-color: #007396; color: white; }
.api-tag { background-color: #d9534f; color: white; }
.matlab-tag { background-color: #0076a8; color: white; }
.docker-tag { background-color: #0db7ed; color: white; }
.tensorflow-tag { background-color: #ff6f00; color: white; }
.c\+\+-tag { background-color: #00599c; color: white; }
.html-tag { background-color: #e34c26; color: white; }
.css-tag { background-color: #264de4; color: white; }
.javascript-tag { background-color: #f0db4f; color: black; }
.matplotlib-tag { background-color: #11557c; color: white; }
.youtube-tag { background-color: #ff0000; color: white; }
.vercel-tag { background-color: #763DA9; color: white; }
.render-tag { background-color: #f24c27; color: white; }
.firebase-tag { background-color: #FFCA28; color: black; }
.firestore-tag { background-color: #FFA611; color: white; }
.hackathon-tag { background-color: #00b140; color: white; }
.tailwind-css-tag { background-color: #38bdf8; color: white; }
.vite-tag { background-color: #64e1fe; color: white; }

/* SLIDESHOW STYLING */
.slideshow-container {
    position: relative;
    max-width: 100%;
}

.mySlides img {
    width: 100%;
    border: 0.1rem solid var(--text-dark);
    border-radius: 5px;
}

.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.prev {
    left: 0;
    border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* BUTTON STYLING */
.btn-container {
    gap: 1rem;
    display: flex;  
    justify-content: center;
    margin-top: auto;
    padding-top: 1rem;
}

.github-btn {
    width: max-content;
    display: inline-block;
    color: var(--color-white);
    background: var(--color-github-green);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-github-gray);
    transition: var(--transition);
}

.github-btn:hover {
    background: var(--color-github-gray);
    color: var(--color-white);
    border-color: var(--color-github-green);
}

/* PAGINATION STYLING */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-top: 2rem;
}

.pagination button {
    padding: 0.75rem 1.2rem;
    border: 3px solid var(--color-white);
    border-radius: 0.4rem;
    background: var(--color-primary);
    color: var(--color-white);
    cursor: pointer;
    font-size: 1rem;
    transition: var(--transition);
}

.pagination button:hover:not(:disabled) {
    border: 3px solid var(--color-primary);
    color: var(--color-primary);
    background: var(--color-white);
}

.pagination button:disabled {
    background-color: var(--color-gray);
    border-color: var(--color-gray);
    cursor: not-allowed;
    opacity: 0.7;
}

.pagination span {
    font-size: 1rem;
    color: var(--color-white);
    font-weight: 500;
}

/* MEDIA QUERIES */
@media screen and (min-width: 1201px) {
    .projects {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .projects {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .project-tabs {
        flex-direction: column;
    }

    .projects {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
    
    .project-tabs label {
        font-size: 1.2rem;
    }
    
    .project {
        margin-right: 0;
    }
    
    .tag {
        margin-bottom: 0.5rem;
    }

    .project-img {
        width: 90%;
        margin: 0 auto;
    }

    .pagination {
        gap: 1rem;
    }

    .pagination button {
        padding: 0.5rem 0.8rem;
        font-size: 0.9rem;
    }

    .pagination span {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 480px) {
    #projects h1 {
        font-size: 1.5rem;
    }
    
    .project-tabs label {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }
    
    .projects {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }

    .project {
        padding: 0.5rem;
    }

    .project-title {
        font-size: 1.25rem;
    }
}