.page {
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
  }
  
  .navbarleft {
    position: absolute;
    top: 0;
    left: 0;
    margin: 1rem;
    z-index: 100;
  }
  
  .navbarright {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    z-index: 100;
  }
  
  .app-title {
    position: absolute;
    z-index: 1000;
    color: white;
    top: 2%;
    left: calc(50% - 2rem);
  }
  
  .login-graph {
    position: relative;
    z-index: 1;
  }
  
  .startup {
    position: absolute;
    top: calc(50% - 14vh);
    left: calc(50% - 28vh);
  }
  
  .dot {
    color: red;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index:1000 ;
  }
  
  .upload-bar {
    position: absolute;
    z-index: 100;
    top:0;
    right: 0;
  }
  
  .position-controls {
    position: absolute;
    z-index: 10000;
    bottom: 0;
    right: calc(50% - 10vw - 1rem);
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 1rem;
  }