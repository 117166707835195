
.popout-button {
    border: none;
    padding: 1rem;
    background-color: transparent;
    color: white;
    animation: slideFromLeft 0.3s backwards;
    margin-left: auto;
}

.popout-button:focus {
    outline: none;
    box-shadow: none;
}

.icon-right {
    cursor: pointer;
    scale: 2.5;
    margin: 1rem;
}

.navbar-container {
    margin: 1rem;
}

.open-navbar-left {
    display: flex;
    flex-direction: column;
    margin: -1rem;
    width: 40vh;
    height: calc(100vh - 4rem);
    background-color: #6666;
    border-radius: 1rem;
    padding: 1rem;
    animation: slideFromLeft 0.3s forwards;
    overflow-y: scroll;
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.top-row {
    display: flex;
    flex-direction: row;
    margin-inline: auto;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}

.search-bar {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.search-bar input {
    padding: 10px 20px 10px 10px;
    border: 1px solid white;
    border-radius: 0.5rem;
    font-size: 16px;
    width: calc(40vh - 16vh);
}

.search-button {
    background-color: transparent; /* Bootstrap primary color */
    color: white; /* Text color */
    padding: 12px 10px; /* Padding for button */
    margin-left: 4px; /* Space between input and button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition */
    border: 1px solid white;
    border-radius: 0.5rem;
    justify-content: center;
}

.search-button:hover {
    background-color: white; /* Darker shade on hover */
}

.search-content {
    display: flex;
    flex-direction: column;
}

.result-count {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: right;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
}

.result-text {
    color: white;
    padding-bottom: 1rem;
}

.counter {
    width: 10%;
    padding: 4px;
    background: transparent;
    color: white;
}